import { lazy } from "react";

const ContactTable = lazy(() => import("./ContactTable"));

const ContactList = lazy(() => import("./ContactList"));

const ContactCard = lazy(() => import("./ContactCard"));

const Leccion = lazy(() => import("./Leccion"));


const EditLesson = lazy( () => import("./EditLesson") );
const EditCourse = lazy( () => import("./EditCourse") );



const NewPregunta = lazy(() => import("./NewPregunta"));

const NewPreguntaEdit = lazy(() => import("./NewPreguntaEdit"));

const NewPreguntaView = lazy(() => import("./NewPreguntaView"));


const Preguntas = lazy(() => import("./Preguntas"));

const Gestores = lazy(() => import("./Profesor"));

const Organizaciones = lazy(() => import("./Cursos"));

const VerMas = lazy(() => import("./VerMas"));

const Permisos = lazy(() => import("./Permisos"));

const Perfil = lazy( () => import("./Perfil") );

const contactRoutes = [
 
  {
    path: "/contact/table",
    component: ContactTable
  },
  {
    path: "/contact/list",
    component: ContactList
  },
  {
    path: "/contact/card",
    component: ContactCard
  },
  {
    path: "/leccion",
    component: Leccion
  },
  {
    path: "/editlesson",
    component: EditLesson
  },
  {
    path: "/editcourse",
    component: EditCourse
  },
  {
    path: "/preguntas",
    component: Preguntas
  },
  {
    path: "/NewPregunta",
    component: NewPregunta
  },
  {
    path: "/NewPreguntaEdit",
    component: NewPreguntaEdit
  },
  {
    path: "/NewPreguntaView",
    component: NewPreguntaView
  },
  {
    path: "/gestores",
    component: Gestores
  },
  {
    path: "/organizaciones",
    component: Organizaciones
  },
 
  {
    path: "/VerMas",
    component: VerMas
  },
  {
    path: "/permisos",
    component: Permisos
  },
  {
    path: "/perfil",
    component: Perfil
  }
];

export default contactRoutes;
