import axios from "axios";
import localStorageService from "./localStorageService";


class JwtAuthService {
  
  user = {
    role: 'ADMIN'
  }

  loginWithEmailAndPassword = async (email, password) => {
    await axios.post( '/login', {user: email, pass: password})
      .then(response => {
        this.user.token = response.data.token;
        this.user.tipo = response.data.user;
      })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(this.user);
        }, 1000);
      }).then(data => {
        this.setSession(data.token);
        this.setUser(data);
        return data;
      });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["authorization"] = token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["authorization"];
    }
  };
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
    localStorage.removeItem("powers");
  }
}

export default new JwtAuthService();
